<template>
  <a-spin :spinning="spinning">
    <!-- <page-header title="模板管理"></page-header> -->

    <!-- <a-row :gutter="8" :style="{ textAlign: 'right', marginTop: '15px' }">
        <a-col class="gutter-row" :span="24">
          <a-button
            type="primary"
            @click="createOrEdit()"
            v-if="isGranted('Pages.LGModul.Template.Create')"
          >
            <a-icon type="file-add" />
            <span>{{ l("创建模板") }}</span>
          </a-button>
        </a-col>
      </a-row> -->
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :hide-row-selection="true"
      :is-full="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      ref="eleT"
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="8">
        <a-col class="gutter-row" :span="5">
          <a-input
            v-model.trim="SearchDto.templateName"
            :placeholder="l('请输入模板名称')"
            allowClear
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button
            type="primary"
            @click="search"
            v-if="isGranted('Pages.LGModul.Template.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" :span="15" :style="{ textAlign: 'right' }">
          <a-button
            type="primary"
            @click="createOrEdit()"
            v-if="isGranted('Pages.LGModul.Template.Create')"
          >
            <a-icon type="file-add" />
            <span>{{ l("创建模板") }}</span>
          </a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import EleTable from "@/components/ele-table";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import CreateOrEditForm from "./action/create-or-edit";

let vm = this;

export default {
  mixins: [AppComponentBase],
  name: "template-index",
  components: { EleTable },

  data() {
    return {
      spinning: false,
      SearchDto: {
        SkipCount: 0,
        MaxResultCount: 50,
        Sorting: "",
        filterText: "",
        templateName: "",
      },
      name: "",
      projectData: [
        // {
        //   value: "1",
        //   text: "测试项目1",
        // },
        // {
        //   value: "2",
        //   text: "测试项目2",
        // },
      ],
      selectedRowKeys: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      tableData: [],
      columns: [],
      child: "",
      commonService: null,
      selectProjectList: "",
    };
  },
  activated() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.$refs.eleT._doLayout()
      })
    }, 300)
  },
  created() {
    console.log(this);
    // this.initChild();
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    // this.getProject();
    this.getData();
    this.initActionsType();
  },
  methods: {
    initColumns() {
      var vm = this;
      //   var child = vm.$createElement("a", {
      //     domProps: {
      //       innerHTML: 66,
      //     },
      //     on: {
      //       click: function () {
      //         vm.signClick();
      //       },
      //     },
      //   });
      this.columns = [
        {
          title: this.l("模板名称"),
          dataIndex: "templateName",
          // sorter: true,
          align: "center",
          width: 220,
          ellipsis: true,
          scopedSlots: { customRender: "templateName" },
        },
        {
          title: this.l("模板描述"),
          dataIndex: "content",
          // sorter: true,
          // align: "center",
          // width: 250,
          ellipsis: true,
          scopedSlots: { customRender: "content" },
        },
        {
          title: this.l("是否执行考勤打卡"),
          dataIndex: "isNeedSignIn",
          // sorter: true,
          align: "center",
          type: 'tag',
          // width: 150,
          scopedSlots: { customRender: "isNeedSignIn" },
          customRender: (text) => {
            return text ? (
              <a-tag color="#87d068">执行</a-tag>
            ) : (
              <a-tag color="#f50">不执行</a-tag>
            );
          },
        },
        {
          title: this.l("是否设置打卡地址"),
          dataIndex: "isSetSignAddress",
          // sorter: true,
          align: "center",
          type: 'tag',
          // width: 150,
          scopedSlots: { customRender: "isSetSignAddress" },
          customRender: (text) => {
            return text ? (
              <a-tag color="#87d068">设置</a-tag>
            ) : (
              <a-tag color="#f50">不设置</a-tag>
            );
          },
        },
        {
          title: this.l("是否需要提交执行记录"),
          dataIndex: "isNeedExecuteRecord",
          // sorter: true,
          align: "center",
          type: 'tag',
          width: 180,
          scopedSlots: { customRender: "isNeedExecuteRecord" },
          customRender: (text) => {
            return text ? (
              <a-tag color="#87d068">需要</a-tag>
            ) : (
              <a-tag color="#f50">不需要</a-tag>
            );
          },
        },
        {
          title: this.l("状态"),
          dataIndex: "isEnabled",
          sorter: true,
          align: "center",
          type: 'tag',
          // width: 120,
          // customRender: function (text, record, index) {
          //   console.log(record);
          //   var status = !text ? "停用" : "启用";
          //   var child = vm.$createElement("a", {
          //     domProps: {
          //       innerHTML: status,
          //     },
          //     on: {
          //       click: function () {},
          //     },
          //   });
          //   var obj = {
          //     children: child,
          //     attrs: {},
          //   };
          //   return obj;
          // },
          scopedSlots: { customRender: "isEnabled" },
          customRender: (text) => {
            return text ? (
              <a-tag color="#87d068">启用</a-tag>
            ) : (
              <a-tag color="#f50">禁用</a-tag>
            );
          },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          fixed: "right",
          // width: 120,
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    initChild(text) {
      var child = this.$createElement("a", {
        domProps: {
          innerHTML: text,
        },
        on: {
          click: function () {
            this.signClick(text);
          },
        },
      });
      this.child = child;
      console.log(child);
    },
    search() {
      this.pageNumber = 1
      this.SearchDto.SkipCount = 0
      this.getData()
    },
    getData() {
      this.getPaged();
      // this.tableData = [
      //   {
      //     name: "快递员任务",
      //     describe: "555",
      //     status: "启用",
      //   },
      // ];
    },
    async getPaged() {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/Task/GetTemplateList",
          params: {
            templateName: this.SearchDto.templateName,
            filterText: this.SearchDto.filterText,
            sorting: this.SearchDto.Sorting,
            maxResultCount: this.SearchDto.MaxResultCount,
            skipCount: this.SearchDto.SkipCount,
          },
        });
        // console.log(res);
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            isNeedSignInColor: item.isNeedSignIn ? '#87d068' : '#f50',
            isSetSignAddressColor: item.isSetSignAddress ? '#87d068' : '#f50',
            isNeedExecuteRecordColor: item.isNeedExecuteRecord ? '#87d068' : '#f50',
            isEnabledColor: item.isEnabled ? '#87d068' : '#f50',
            isNeedSignIn: item.isNeedSignIn ? '执行' : '不执行',
            isSetSignAddress: item.isSetSignAddress ? '设置' : '不设置',
            isNeedExecuteRecord: item.isNeedExecuteRecord ? '需要' : '不需要',
            isEnabled: item.isEnabled ? '启用' : '禁用',
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.SearchDto.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
          this.pageNumber * this.SearchDto.MaxResultCount,
        ];
        this.$refs.eleT._doLayout()
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "sign-manage",
        isShow: true,
        fns: {
          isShow: true,
          dropList: [
            // {
            //   granted: true,
            //   name: this.l("查看"),
            //   icon: "profile",
            //   fn: (data) => {
            //     _this.createOrEdit(data, "preview");
            //   },
            // },
            {
              granted: this.isGranted("Pages.LGModul.Template.Edit"),
              name: this.l("Edit"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data, "edit");
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.Template.EnableDisable"),
              name: this.l("禁用"),
              icon: "stop",
              fn: (data) => {
                _this.stopItem(data.id, "edit");
              },
            },
          ],
          // delete: {
          //   granted: this.isGranted("Pages.Customer.Delete"),
          //   name: this.l("Delete"),
          //   fn: (data) => {
          //     _this.deleteItem(data.id);
          //   },
          // },
        },
      };
      this.actionsType = obj;
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * this.SearchDto.MaxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    lookSign(item) {
      const { pdfTemplateUrl } = item;
      if (pdfTemplateUrl) {
        window.open(`${AppConsts.uploadBaseUrl}${pdfTemplateUrl}`);
      } else {
        this.$message.info("暂无协议");
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    refreshGoFirstPage() {
      this.SearchDto.templateName = "";
      this.SearchDto.filterText = "";
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    createOrEdit(item) {
      console.log(item);
      ModalHelper.create(
        CreateOrEditForm,
        {
          id: item ? item.id : "",
        },
        {
          isChange: true,
          width: "1000px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    deleteItem(id) {
      let _that = this;
      // _that.message.confirm(`是否确认删除`, "删除", (res) => {
      //   if (res) {
      //     _that.delData(id);
      //   }
      // });
      _that.delData(id);
    },
    stopItem(id) {
      this.stopData(id);
    },
    async stopData(id) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Task/ForbiddenTemplates",
          params: {
            id,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: "成功",
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async delData(id) {
      this.spinning = true;
      let ids = [];
      ids.push(id);
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/Task/DelTaskTemplate",
          params: id,
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: res,
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
</style>

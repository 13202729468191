<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>{{ l("添加考勤打卡地址") }}</span>
      </div>
    </div>
    <!-- <a-row :gutter="8">
        <a-col class="gutter-row" :span="20">
          <a-input v-model="filterText" :placeholder="l('输入地址搜索')" />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button type="primary" @click="getData">
            {{ l("Search") }}
          </a-button>
        </a-col>
      </a-row> -->

    <a-form
      :form="form"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      @submit="handleSubmit"
    >
      <a-form-item class="_mapBox">
        <!-- <a-input
          v-model="addressKeyword"
          :placeholder="l('请输入地址来直接查找相关位置')"
        /> -->
        <tx-map :show="showModal" :width="700" @close="closeModal" @selectAddress="selectAddressModal"></tx-map>
      </a-form-item>
      <a-form-item label="打卡范围" has-feedback class="_itemBox">
        <div class="_item">
          <a-radio-group
            v-model="isSettingRange"
            @change="onChangeSettingRange"
            style="display: flex"
          >
            <!-- <a-radio :value="true"> 设置 </a-radio>
            <a-radio :value="false"> 不设置 </a-radio> -->
          </a-radio-group>
          <a-select
            style="width: 180px; margin-right: 30px"
            @change="selectChange"
            placeholder="请选择"
            allowClear
            v-if="isSettingRange"
          >
            <a-select-option :value="it.value" v-for="(it, i) in list" :key="i">
              {{ it.label }}
            </a-select-option>
          </a-select>
          <div v-if="isSettingRange && iscustom">
            <a-input
              v-model="distance"
              :placeholder="l('自定义')"
              style="width: 180px; margin-right: 10px"
            />米
          </div>
        </div>
      </a-form-item>
      <a-form-item label="打卡时间段" has-feedback>
        <a-radio-group v-model="isSettingTime" @change="onChangeSettingTime">
          <a-radio :value="true"> 设置 </a-radio>
          <a-radio :value="false"> 不设置 </a-radio>
        </a-radio-group>
        <div v-if="isSettingTime" style="display: inline-block">
          <a-time-picker
            :default-value="moment('00:00', 'HH:mm')"
            format="HH:mm"
            style="width: 180px"
            @change="onChangeStartTime"
          />
          <span style="margin: 0 10px">~</span>
          <a-time-picker
            :default-value="moment('23:59', 'HH:mm')"
            format="HH:mm"
            style="width: 180px"
            @change="onChangeendTime"
          />
        </div>
      </a-form-item>
      <a-form-item class="btn--container" style="text-align: center">
        <a-button type="button" @click="close()">
          {{ l("Cancel") }}
        </a-button>
        <a-button type="primary" html-type="submit">
          {{ l("Save") }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-spin>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
import moment from "moment";
import TxMap from "../component/tx-map.vue";
export default {
  mixins: [ModalComponentBase],
  name: "address-manage",
  components: {
    TxMap,
  },
  data() {
    return {
      spinning: false,
      filterText: "",
      addressKeyword: "",
      lng: "",
      lat: "",
      point: "",
      list: [
        { label: "100米", value: 100 },
        { label: "200米", value: 200 },
        { label: "300米", value: 300 },
        { label: "400米", value: 400 },
        { label: "500米", value: 500 },
        { label: "自定义", value: 0 },
      ],
      isSettingRange: true,
      isSettingTime: true,
      map: {
        center: { lng: 116.404, lat: 39.915 },
        zoom: 15,
      },
      postionMap: "",
      startTime: "00:00",
      endTime: "23:59",
      distance: "",
      iscustom: false,
      showModal: true,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  methods: {
    moment,
    onChangeSettingRange(e) {
      if (!e.target.value) {
        this.distance = "";
        this.iscustom = false;
      }
      this.isSettingRange = e.target.value;
    },
    onChangeSettingTime(e) {
      this.isSettingTime = e.target.value;
    },
    getData() {},
    handleSubmit(e) {
      let _that = this;
      if (!_that.addressKeyword) {
        _that.message.warn("请选择地址！");
        return;
      }
      if (_that.isSettingRange && !_that.distance) {
        _that.message.warn("请设置打卡范围！");
        return;
      }
      _that.form.validateFieldsAndScroll((err, values) => {
        console.log(err);
        console.log(values);
        console.log(_that.addressKeyword);
        // if (!_that.isSettingTime) {
        //   _that.message.warn("请设置打卡时间！");
        //   return;
        // }
        if (!err) {
          let address = {
            address: _that.addressKeyword,
            longitude: _that.lng,
            dimension: _that.lat,
            isSetScop: _that.isSettingRange,
            scope: !_that.isSettingRange ? 0 : _that.distance,
            isSetSignTime: _that.isSettingTime,
            startTime: !_that.isSettingTime ? "" : _that.startTime,
            endTime: !_that.isSettingTime ? "" : _that.endTime,
          };
          _that.success(address);
        }
      });
    },
    selectChange(value) {
      if (value == 0) {
        this.iscustom = true;
        this.distance = "";
      } else {
        this.distance = value;
      }
      console.log(value);
    },

    onChangeStartTime(time, timeString) {
      this.startTime = timeString;
      console.log(time, timeString);
    },
    onChangeendTime(time, timeString) {
      this.endTime = timeString;
      console.log(time, timeString);
    },
    closeModal() {
      this.showModal = false;
    },
    selectAddressModal(data){
      console.log(data);
      this.addressKeyword = data.address;
      this.lng = data.longitude;
      this.lat = data.latitude;
    }
  },
};
</script>
<style lang="less" scoped>
/deep/.btn--container {
  display: block;
  margin: 5px auto;
  text-align: center;
}
._itemBox {
  display: flex;
  align-items: center;
}
._item {
  display: flex;
  align-items: center;
}
._mapBox {
  display: flex;
  justify-content: center;
}
.map {
  width: 100%;
  height: 400px;
}
.bmView {
  width: 100%;
  height: 400px;
}
</style>
<template>
  <!-- <a-modal :title="title" :visible="showModel" :width="width" @cancel="onClose" @ok="ok"> -->
   <div>
    <p></p>
    <div :key="mapKey" :id="'container_' + mapKey" :style="'width:' + (width - 80) + 'px;height:400px;'"></div>
    <p class="addrBox" v-if="isSearch">经度：{{longitude}} 纬度：{{latitude}} 地址：{{address}}</p>
    <div class="searchBox" style="margin-top:10px;">
    <!-- <a-button type=""  @click="buttonPosition" icon="environment" :loading="loading">定位</a-button> -->
    <a-input-search class="input_search"  placeholder="地址搜索" v-model="searchAdress" enter-button @change="onSearch(1)"	 @search="onSearch(1)" />
    </div>
    <a-list class="listBox" v-if="isSearch">
      <a-list-item @click="selectAddr(addr)" :key="idx" v-for="(addr, idx) in narAddress"><h4>{{addr.name}} <a-tag>{{addr.category}}</a-tag></h4>
        <br><h5>地址：{{addr.address}}</h5></a-list-item>
    </a-list>
 </div>
  <!-- </a-modal> -->
</template>

<script>
export default {
  props: {
    show: {
      default: false
    },
    width: {
      default: 600
    },
    title: {
      default: ''
    }
  },
  name: 'tx-map',
  computed: {
    showModel: {
      get: function () {
        this.setMap()
        return this.show
      },
      set: function () {
      }
    }
  },
  data () {
    return {
      mapKey: new Date().getTime(),
      /** loading **/
      loading: false,
      /** 地址 **/
      searchAdress: '',
      detailcontent: '这是我当前的位置',
      address: '',

      longitude: 106.72622323036194, // 经度
      latitude: 26.86139511254856, // 纬度
      city: '',
      /** 定位 **/
      geolocation: null,

      myLatlng: null,
      map: null,
      geo: null,

      markerSelect: null,
      labelMarkerSelect: null,
      narAddress: [],
      zoom: 13,
      isSearch:false,
    }
  },
  watch: {
  },
  methods: {

    /** **/
    onClose () {
      this.$emit('close')
    },
    initMap (location = null) {
      // let _this = this
      if (!location) {
        this.getPosition()
        // setTimeout(function () {
        //   _this.setMap()
        // }, 3000)
      } else {
        this.longitude = location.lng
        this.latitude = location.lat
        this.setMap()
        // setTimeout(function () {
        //   _this.setMap()
        // }, 3000)
      }
    },

    /** ***/
    ok () {
      let location = {
        lat: this.latitude,
        lng: this.longitude
      }
      this.$emit('position', location)
      this.$emit('close')
    },

    /** 进行地址搜索 **/
    onSearch (sign) {
      let _this = this
      if(sign ==1) {
      _this.isSearch = true;
      }
      // returnCitySN["cip"]+','+returnCitySN["cname"] returnCitySN['cname'] 在index.html 引入了<script src="http://pv.sohu.com/cityjson?ie=utf-8">
      let search = returnCitySN['cname'].split('省')[0] + '省,' + returnCitySN['cname'].split('省')[1]
      if (_this.searchAdress) {
        search += ',' + _this.searchAdress
      }
      console.log('search:', search)
      _this.geo.getLocation(search)// 地址
      _this.geo.setComplete(function (res) {
        console.log(res)
        _this.latitude = res.detail.location.lat
        _this.longitude = res.detail.location.lng
        _this.detailcontent = res.detail.address
        _this.address = res.detail.address
        _this.setMap()
        _this.detailcontent = '这是我当前的位置'

        // 
        let center = new qq.maps.LatLng(_this.latitude, _this.longitude)
        // 
        var geocoder = new qq.maps.Geocoder({
          complete (res) {
            console.log(res)
            _this.narAddress = res.detail.nearPois
          }
        })
        if(sign==1){
               _this.$emit('selectAddress', {longitude:_this.longitude,latitude:_this.latitude,address:_this.address});
        }
        geocoder.getAddress(center)
      })
    },

    /** 按钮 **/
    buttonPosition () {
      this.loading = true
      this.getPosition()
    },

    // 第一部分
    /** 定位获得当前位置信息 **/
    getMyLocation () {
      this.geolocation = new qq.maps.Geolocation('GOZBZ-MWHKV-GDSPP-UPRNN-YUWEE-EHB5U', 'shopMap')
      this.getPosition()
    },
    /** 执行定位 **/
    getPosition () {
      // this.geolocation.getIpLocation(this.showPosition, this.showErr)
      this.geolocation.getLocation(this.showPosition, this.showErr)// 或者用getLocation精确度比较高
    },
    showPosition (position) {
      let _this = this
      console.log('定位成功：', position)
      this.latitude = position.lat // 经度
      this.longitude = position.lng // 纬度
      this.city = position.city
      // 
      let center = new qq.maps.LatLng(this.latitude, this.longitude)
      // 
      var geocoderPos = new qq.maps.Geocoder({
        complete (res) {
          console.log('定位成功', res)
          _this.narAddress = res.detail.nearPois
        }
      })
      geocoderPos.getAddress(center)
      this.setMap()
      // 消除loading
      this.loading = false
      this.address = position.province + position.city
    },
    showErr () {
      console.log('定位失败：')
      this.getPosition()// 定位失败再请求定位，测试使用
    },
    // 第二部分
    /** 位置信息在地图上展示 **/
    setMap () {
      let _this = this
      // 步骤：定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
      // 设置地图中心点
      let center = new qq.maps.LatLng(this.latitude, this.longitude)
      // 定义工厂模式函数
      var myOptions = {
        zoom: _this.zoom, // 设置地图缩放级别
        center: center, // 设置中心点样式
        mapTypeId: qq.maps.MapTypeId.ROADMAP // 设置地图样式详情参见MapType
      }
      //获取dom元素添加地图信息
      let map = new qq.maps.Map(document.getElementById('container_' + _this.mapKey), myOptions)
      // 第三部分
      /** 给定位的位置添加图片标注 **/
      var marker = new qq.maps.Marker({
        position: center,
        map: map
      })
      // 给定位的位置添加文本标注
      var marker = new qq.maps.Label({
        position: center,
        map: map,
        content: this.detailcontent
      })
      /** 信息标记 **/
      var infoWin = new qq.maps.InfoWindow({
        map: map
      })
      /** 点击后生成的地表名称 **/
      let address = '此地'
      var geocoder = new qq.maps.Geocoder({
        complete (res) {
          console.log(res)
          _this.isSearch = true;

          address = res.detail.nearPois[0].name // 这里是你点击之处根据经纬度返回的地点名称
                    _this.address = res.detail.address;
                    _this.longitude = res.detail.location.lng;
                    _this.latitude = res.detail.location.lat;
                     _this.$emit('selectAddress', {longitude:_this.longitude,latitude:_this.latitude,address:_this.address});
          _this.narAddress = res.detail.nearPois
        }
      })
      // 第四部分
      /** 给地图添加点击事件，并获取鼠标点击的经纬度 **/
      qq.maps.event.addListener(map, 'click', function (event) {
        console.log(event)
        _this.isSearch = true;//点击
        this.longitude = event.latLng.getLng()
        this.latitude = event.latLng.getLat()

        geocoder.getAddress(event.latLng)
        setTimeout(() => {
          infoWin.open()
          infoWin.setContent(`<div style="text-align:center;white-space:nowrap;">${address}</div>`)
          infoWin.setPosition(event.latLng)
        }, 200)
      })
    },
    /** 选中地址 **/
    selectAddr (addr) {
      let _this = this
      this.longitude = addr.latLng.lng
      this.latitude = addr.latLng.lat
      _this.address = addr.address
      _this.zoom = 17
      _this.detailcontent = addr.name
      this.setMap();
         this.$emit('selectAddress', {longitude:_this.longitude,latitude:_this.latitude,address:_this.address});
    },
  },
  mounted () {
    const oScript = document.createElement('script');
    oScript.type = 'text/javascript';
    oScript.src = 'http://pv.sohu.com/cityjson?ie=utf-8';
    document.body.appendChild(oScript);
    setTimeout(() => {
      this.setMap()
      this.onSearch()
    }, 500)
  },
  created () {
    this.geolocation = new qq.maps.Geolocation('TZ6BZ-TGSK5-BDCIV-Q3I66-TP5A3-HUF4A', 'shopMap')
    console.log(this.geolocation)
    this.geo = new qq.maps.Geocoder()
       console.log(this.geo)
  },
  beforeDestroy() {
    let sArr = document.getElementsByTagName('script')
    console.log(sArr)
    sArr.forEach(ele => {
      if (ele.src === 'http://pv.sohu.com/cityjson?ie=utf-8') {
        let node = document.getElementsByTagName('body')[0]
        node.removeChild(ele)
      }
    })
    console.log(sArr)
  },
}
</script>

<style scoped>
.listBox {
height: 150px;overflow-y: scroll;padding-right: 10px;
}
.addrBox {
    margin:0;color:#ff0000cf;
}
.searchBox {
    display: flex;

}
    .input_search {
        margin-left: 4px;
    }
</style>
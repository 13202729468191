<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="id">{{ l("修改模板") }}</span>
        <span v-if="!id">{{ l("添加模板") }}</span>
      </div>
    </div>
    <div>
      <!-- <a-steps :current="current">
        <a-step v-for="item in steps" :key="item.title" :title="item.title" />
      </a-steps> -->
      <div class="steps-content">
        <!-- {{ steps[current].content }} -->
        <div>
          <a-form
            :form="form"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-form-item label="模板名称" has-feedback>
              <a-input
                placeholder="请输入模板名称"
                v-decorator="[
                  'templateName',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('validation.required'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item label="模板描述" has-feedback>
              <a-textarea
                :auto-size="{ minRows: 2, maxRows: 6 }"
                :placeholder="l('请输入模板描述')"
                v-decorator="['content', { rules: [],initialValue:'' }]"
              />
            </a-form-item>
            <a-form-item label="是否启用">
              <a-switch
                checked-children="是"
                un-checked-children="否"
                v-decorator="[
                  'isEnabled',
                  { valuePropName: 'checked', initialValue: false },
                ]"
              />
            </a-form-item>
          </a-form>
        </div>
        <div>
          <div class="item_box">
            <div class="item_icon">
              <span>1</span>
            </div>
            <span class="item_title">{{ l("考勤设置") }}</span>
          </div>
          <div>
            <a-form
              :form="form1"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-form-item label="是否执行考勤打卡?" has-feedback>
                <a-radio-group
                  v-model="isNeedSignIn"
                  @change="onChangeSettingClock"
                >
                  <a-radio :value="true"> 执行 </a-radio>
                  <a-radio :value="false"> 不执行 </a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="是否设置打卡地址:" has-feedback v-if="isNeedSignIn">
                <a-radio-group
                  v-model="isSetSignAddress"
                  @change="onChangeSettingClockAddress"
                >
                  <a-radio :value="true"> 设置 </a-radio>
                  <a-radio :value="false"> 不设置 </a-radio>
                </a-radio-group>
                <a-tag
                  v-if="isSetSignAddress"
                  color="blue"
                  style="background: #fff; borderstyle: dashed"
                  @click="addAddress"
                >
                  <a-icon type="plus" />
                </a-tag>
              </a-form-item>
            </a-form>
            <div class="_address" v-if="isSetSignAddress && isNeedSignIn">
              <div class="tag" v-for="(item, index) in areaList" :key="index" :title="'打卡范围：'+ item.scope + '米' + ' 打卡时间段：'+ (item.isSetSignTime?item.startTime+'~'+item.endTime:'不设置')">
                地址{{ index + 1 }}：{{ item.address }}
                <!-- <span style="color:red">打卡范围：</span>{{item.scope}}
                <span style="color:red">打卡时间段：</span>{{item.isSetSignTime?item.startTime+"~"+item.endTime:"不设置"}} -->
                <a-tag
                  color="red"
                  style="margin-left: 10px"
                  @click="delAddress(index)"
                >
                  <a-icon type="close" />
                </a-tag>
              </div>
              <!-- <div class="tag">地址2：广州海珠区琶洲新街12栋</div> -->
            </div>
          </div>
          <div class="item_box">
            <div class="item_icon">
              <span>2</span>
            </div>
            <span class="item_title">{{ l("执行记录设置") }}</span>
          </div>
          <div>
            <a-form
              :form="form2"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-form-item label="是否需要上传执行记录:" has-feedback>
                <a-radio-group
                  v-model="isNeedExecuteRecord"
                  @change="onChangeSettingReportRecord"
                >
                  <a-radio :value="true"> 执行 </a-radio>
                  <a-radio :value="false"> 不执行 </a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item
                label="选择执行记录表单"
                has-feedback
                v-if="isNeedExecuteRecord"
              >
                <a-select
                  style="width: 100%"
                  @change="selectChange"
                  placeholder="请选择"
                  allowClear
                  v-decorator="[
                    'collectFormtext',
                    {
                      rules: [
                        {
                          required: true,
                          message: l('validation.required'),
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    :value="it.value"
                    v-for="(it, i) in collectData"
                    :key="i"
                  >
                    {{ it.text }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
      <a-form-item class="btn--container" style="text-align: center">
        <a-button type="button" @click="close()">
          {{ l("Cancel") }}
        </a-button>
        <a-button type="primary" @click="handleSubmit()">
          {{ l("Save") }}
        </a-button>
      </a-form-item>
      <!-- <div class="steps-action">
        <a-button
          v-if="current < steps.length - 1"
          type="primary"
          @click="next"
        >
          下一步
        </a-button>
        <a-button
          v-if="current == steps.length - 1"
          type="primary"
          @click="$message.success('Processing complete!')"
        >
          确认
        </a-button>
        <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">
          上一步
        </a-button>
      </div> -->
    </div>
  </a-spin>
</template>
<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { ModalHelper } from "@/shared/helpers";
import AddressManageForm from "./address-manage";
import moment from "moment";
export default {
  mixins: [AppComponentBase, ModalComponentBase],
  name: "create-or-edit",
  components: {},
  data() {
    return {
      commonService: null,
      spinning: false,
      current: 0,
      steps: [
        {
          title: "开始",
          content: "First-content",
        },
        {
          title: "结束",
          content: "Last-content",
        },
      ],
      isNeedSignIn: true,
      isSetSignAddress: true,
      isNeedExecuteRecord: true,
      collectData: [],
      collectFormId: "",
      areaList: [
        // {
        //   address: "广州海珠区琶洲新街12栋",
        //   longitude: "",
        //   dimension: "",
        //   isSetScop: "",
        //   scope: "",
        //   isSetSignTime: "",
        //   startTime: "",
        //   endTime: "",
        // },
        // {
        //   address: "广州海珠区琶洲新街12栋",
        //   longitude: "",
        //   dimension: "",
        //   isSetScop: "",
        //   scope: "",
        //   isSetSignTime: "",
        //   startTime: "",
        //   endTime: "",
        // },
      ],
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
    this.form1 = this.$form.createForm(this, { name: "register" });
    this.form2 = this.$form.createForm(this, { name: "register" });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
    console.log(this);
    // this.form.setFieldsValue({
    //   isEnabled: false,
    // });
    if (this.id) {
      this.getData();
    }
    this.getCollectForms();
  },
  methods: {
    next() {
      this.current++;
    },
    prev() {
      this.current--;
    },
    addAddress() {
      let _that = this;
      ModalHelper.create(
        AddressManageForm,
        {},
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          _that.areaList.push(res);
          console.log(_that.areaList);
        }
      });
    },
    onChangeSettingClock(e) {
      this.isNeedSignIn = e.target.value;
    },
    onChangeSettingClockAddress(e) {
      this.isSetSignAddress = e.target.value;
    },
    onChangeSettingReportRecord(e) {
      this.isNeedExecuteRecord = e.target.value;
    },
    handleSubmit(e) {
      // e.preventDefault();
      let _that = this;
      this.form.validateFieldsAndScroll((err, values) => {
        console.log(err);
        console.log(values);
        if (!err) {
          
          // if (!values.isEnabled) values.isEnabled = false;
          if (_that.isNeedSignIn && _that.isSetSignAddress && _that.areaList.length == 0) {
            _that.message.warn("请设置打卡地址！");
            return;
          }
          if (_that.isNeedExecuteRecord && !_that.collectFormId) {
            _that.message.warn("请选择执行记录表单！");
            return;
          }
          let obj = {
            isNeedSignIn: _that.isNeedSignIn,
            isSetSignAddress: _that.isSetSignAddress,
            isNeedExecuteRecord: _that.isNeedExecuteRecord,
            collectionId: !_that.isNeedExecuteRecord ? "" : _that.collectFormId,
            lGTaskSignAreas: _that.isNeedSignIn && _that.isSetSignAddress ? _that.areaList:[],
            ...values,
            id: _that.id ? _that.id : 0,
          };
          console.log(obj);
          _that.saveTaskTemplate(obj);
        }
      });
    },
    async saveTaskTemplate(item) {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/Task/SaveTaskTemplate",
          params: {
            ...item,
          },
        });
        this.$notification["success"]({
          message: "添加成功",
        });
        this.success(true);
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    selectChange(value) {
      this.collectFormId = value;
    },
    async getData() {
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Task/GetTemplate",
          params: { id: this.id },
        });
        this.spinning = true;
        console.log(res);
        this.form.setFieldsValue({
          templateName: res.templateName,
          content: res.content,
          isEnabled: res.isEnabled,
        });
        res.lgTaskSignAreas.forEach((item)=>{
          item.startTime = item.startTime
              ? moment(item.startTime).format("HH:mm")
              : "-";
               item.endTime = item.endTime
              ? moment(item.endTime).format("HH:mm")
              : "-";
        })
        this.areaList.push(...res.lgTaskSignAreas);
        this.isNeedSignIn = res.isNeedSignIn;
        this.isSetSignAddress = res.isSetSignAddress;
        this.isNeedExecuteRecord = res.isNeedExecuteRecord;
        this.collectFormId = res.collectionId;
        this.form2.setFieldsValue({
          collectFormtext: res.collectionId.toUpperCase(),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async getCollectForms() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Form/GetSelectList",
          params: {formType:2},
        });
        console.log(res);
        this.collectData = res.map((item) => {
          return {
            text: item.text,
            value: item.value,
          };
        });
        // this.selectProjectList = res.result;
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    delAddress(value) {
      console.log(value);
      this.areaList.splice(value, 1);
    },
  },
};
</script>
<style scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  /* background-color: #fafafa; */
  min-height: 200px;
  /* text-align: center; */
  padding: 50px 0;
}

.steps-action {
  margin-top: 24px;
}
.item_box {
  display: flex;
  align-items: center;
  height: 32px;
  margin: 0 0 10px 100px;
}
.item_box .item_icon {
  background: #1890ff;
  border-color: #1890ff;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  /* border: 1px solid rgba(0, 0, 0, 0.25); */
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.item_box .item_icon span {
  color: #fff;
}
.item_box .item_title {
  /* color: #fff; */
  font-size: 19px;
  font-weight: bold;
}
._address {
  margin-left: 320px;
}
._address .tag {
  padding: 6px 0;
}
.btn--container {
  margin-top: 20px;
}
</style>